$self-serve-primary-background-color: #c4c4c4;
$self-serve-primary-foreround-color: #ffffff;
$self-serve-primary-border-radius: 8px;
@font-face {
	font-family: CircularStdBlack;
	src: url('./fonts/CircularStd/CircularStd-Black.otf') format('opentype');
}

@font-face {
	font-family: CircularStdBold;
	src: url('./fonts/CircularStd/CircularStd-Bold.otf') format('opentype');
}

@font-face {
	font-family: CircularStdBook;
	src: url('./fonts/CircularStd/CircularStd-Book.otf') format('opentype');
}

@font-face {
	font-family: CircularStdMedium;
	src: url('./fonts/CircularStd/CircularStd-Medium.otf') format('opentype');
}

$CircularStdBlack: CircularStdMedium;
$CircularStdBook: CircularStdBook;
$CircularStdMedium: CircularStdMedium;
$self-serve-primary-font-family: CircularStdBlack;
