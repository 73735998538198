@import './variables.scss';
.login__screen__container {
	position: relative;
	width: 100%;
	height: 100vh;

	background: #ffffff;
	border-radius: 16px;
	margin: auto;

	.image-placeholder {
		position: absolute;
		height: 9.5vh;
		top: 20.91vh;
		display: flex;
		justify-content: center;
		width: 100%;
		.ant-image {
			width: 70%;
		}
		.ant-image-img {
			height: 100%;
		}
	}

	.login__text {
		width: 100%;
		position: absolute;
		height: 32px;
		top: 41.59vh;
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 134%;
		color: #000000;
		text-align: center;
	}
}
.login__screen__list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;
	width: 100%;
	height: 256px;
	padding: 0 16px;
	top: 41.64vh;
}
.form-container {
	width: 100%;
}
.ant-form-item {
	margin-bottom: 0;
}

.login__screen__autolayout {
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 16px 0px;
}

.login__screen__input__list {
	.login__screen__input__field {
		position: relative;
		width: 100%;
		height: 8.6vh;
		left: 0px;
		top: 0px;

		.password-label {
			display: flex;
		}

		input {
			position: absolute;
			left: 0%;
			right: 0%;
			bottom: 0%;
			background: #ffffff;
			border: 1px solid #d8d8d8;
			box-sizing: border-box;
			height: 56px;
			width: 100%;
			top: 24px;
			border-radius: 0px;
		}
	}
}

.frame31 {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	.login-checkbox {
		width: auto;
		margin: auto;
		label {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;
		}
	}
}

.login__screen__button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	width: 100%;
	height: 48px;
	left: 0px;
	border: none;
	color: #ffffff;
	background: #ca0d1b;
	margin: 50px 0px 10px;
	font-family: $CircularStdBlack;
	cursor: pointer;
	&:hover {
		opacity: 0.9;
	}
}

.language-selection {
	position: absolute;
	// top: 70vh;
	padding: 16px;
	right: 0;
	.info-title {
		font-family: $CircularStdMedium;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 149.5%;
		color: #212121;
		margin: 16px 0;
	}
}
.login-loading {
	position: absolute;
	left: calc(50% - 11px);
	top: calc(50%);
}

.forgot-password {
	font-family: $CircularStdBlack;
	font-style: italic;
	font-weight: 450;
	font-size: 12px;
	line-height: 136%;
	text-decoration-line: underline;
	color: #ca0d1b;
	margin-left: auto;
}
