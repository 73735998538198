@import './variables.scss';
.account-orders-top-container {
	width: 100%;
	background-color: #ebebe6;
	border-radius: $self-serve-primary-border-radius;
	margin-top: 16px;
	.account-name {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 900;
		font-size: 1.25rem;
		line-height: 121%;
		color: #212121;
	}
	.account-info {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 1rem;
		color: #212121;
	}
}
.account-orders {
	height: 70vh;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-height: 100%;
		max-width: 92%;
		min-height: 97%;
	}
	.page-header-text {
		margin-top: 20px;
		margin-bottom: 0;
	}
	.anticon {
		float: right;
		margin-right: -15px;
		margin-top: -30px;
		svg {
			transform: scale(1.4);
		}
	}

	.search-height {
		min-height: 60px;
	}
	.header-title {
		min-height: 30px;
	}
	.filter-buttons-height {
		min-height: 50px;
	}
	.sub-topic-height {
		min-height: 60px;
	}
	.main-topic-height {
		min-height: 43px;
	}
	.active-filter {
		background-color: #212121;
		color: #fff;
	}
	.inactive-filter {
		background-color: #f4f4f4;
		color: #63666a;
	}
	.filter {
		width: 80px;
		height: 40px;
		border-radius: 8px;
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 1rem;
		border: 0px solid transparent;
		margin-top: 8px;
		.filter-button-icon {
			width: 20px;
			position: relative;
			left: -7px;
			z-index: 0;
		}
		span {
			position: relative;
		}
	}
	.filter:focus {
		border: 1px solid #d0d0d0;
	}
	.search {
		margin-top: 16px;
		background-color: #f4f4f4;
		border-radius: 8px;
		border: 0px;
		height: 40px;
		input {
			background-color: #f4f4f4;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 1rem;
			color: #5f5f5f;
		}

		.ant-image-img {
			width: 8vw;
		}
	}
	.filter-name-text {
		margin-top: 16px;
		margin-bottom: 8px;
		font-family: $CircularStdBlack;
		font-style: normal;
		font-size: 1rem;
		line-height: 149.5%;
		color: #000000;
	}
	.order-container {
		flex: 1;
		overflow-y: auto;
		align-items: flex-start;
		.order-item {
			background-color: #f5f5f2;
			border-radius: 8px;
			margin-bottom: 16px;
			.order-item-order-no {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 900;
				font-size: 1.25rem;
				line-height: 121%;
				color: #212121;
				margin-bottom: 0;
			}
			.order-item-account-info {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				color: #000099;
			}
			.updated-date {
				font-size: 0.75rem !important;
			}
			.order-item-order-detail {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
			.space-maker {
				height: 12px;
			}
			.chavlon-icon {
				top: 33%;
				position: relative;
			}
			.ant-card-body {
				padding: 16px 24px;
			}
			.ant-progress-success-bg,
			.ant-progress-bg {
				background-color: #63666a;
				height: 16px !important;
				background-image: linear-gradient(
					270deg,
					#88888d -62.08%,
					rgba(136, 136, 141, 0) 150%
				);
				box-shadow: inset 1px -2px 1px rgba(213, 213, 213, 0.05),
					inset -1px 2px 1px rgba(80, 80, 80, 0.1),
					inset 1px 2px 1px rgba(212, 212, 212, 0.05),
					inset -1px -2px 1px rgba(80, 80, 80, 0.1);
				border-radius: 4px;
			}
			.ant-progress-inner {
				height: 16px;
				background-color: #d8d8d8;
				box-shadow: inset 0px 4px 4px rgba(69, 69, 69, 0.25);
				border-radius: 4px;
			}
		}
	}
}
