@import './variables.scss';
.order-item {
	background-color: #f5f5f2;
	border-radius: 8px;
	margin-bottom: 10px;
	.order-item-order-no {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 900;
		font-size: 1.25rem;
		line-height: 121%;
		color: #212121;
		margin-bottom: 0;
	}
	.order-item-account-info {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 16px;
		color: #000099;
	}
	.updated-date {
		font-size: 0.75rem !important;
	}
	.order-item-order-detail {
		font-family: $CircularStdBlack;
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 16px;
		color: #212121;
	}
	.space-maker {
		height: 12px;
	}
	.chavlon-icon {
		top: 33%;
		position: relative;
	}
	.ant-card-body {
		padding: 16px 24px;
	}
	.ant-progress-success-bg,
	.ant-progress-bg {
		background-color: #63666a;
		height: 16px !important;
		background-image: linear-gradient(
			270deg,
			#88888d -62.08%,
			rgba(136, 136, 141, 0) 150%
		);
		box-shadow: inset 1px -2px 1px rgba(213, 213, 213, 0.05),
			inset -1px 2px 1px rgba(80, 80, 80, 0.1),
			inset 1px 2px 1px rgba(212, 212, 212, 0.05),
			inset -1px -2px 1px rgba(80, 80, 80, 0.1);
		border-radius: 4px;
	}
	.ant-progress-inner {
		height: 16px;
		background-color: #d8d8d8;
		box-shadow: inset 0px 4px 4px rgba(69, 69, 69, 0.25);
		border-radius: 4px;
	}
}
