@import './variables.scss';
.common-page-container {
	.topbar {
		position: absolute;
		// top: 96px;
		width: 100%;
	}
	.dashboard-container {
		position: absolute;
		top: 48px;
		margin-top: 20px;
		.banner-notification {
			background: #ca0d1b;
			padding: 0 16px;
			.text {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 900;
				font-size: 14px;
				line-height: 16px;
				/* identical to box height, or 114% */
				text-align: center;

				/* White */
				color: #ffffff;
			}
		}
		.description {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0 16px;
			.title {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 900;
				font-size: 22px;
				line-height: 30px;
			}
			.text {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 16px;
				line-height: 20px;
			}
		}
	}
}
