@import './variables.scss';
.help {
	height: 84vh;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-height: 100%;
		max-width: 92%;
		min-height: 100%;
		.support-title {
			margin-top: 24px;
			margin-bottom: 0;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 32px;
			color: #212121;
		}
		.support-sub-title {
			margin-top: 24px;
			margin-bottom: 0;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 24px;
			color: #212121;
		}
		.help-sub-link-1 {
			margin-top: 24px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			display: flex;
			align-items: center;
			color: #212121;
		}
		.ant-divider-horizontal {
			opacity: 0.25;
			border: 1px solid #5f5f5f;
		}
		.help-sub-link-2 {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 0.875rem;
			line-height: 16px;
			display: flex;
			align-items: center;
			color: #212121;
			margin-top: 0;
			margin-bottom: 0;
		}
		.feedback-title {
			margin-top: 16px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: bold;
			font-size: 1rem;
			line-height: 24px;
			color: #212121;
		}
		.ant-checkbox-input {
			border: 1px solid #88888d;
			box-sizing: 'border-box';
		}
		.checkbox-text {
			margin-top: 24px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 14px;
			line-height: 16px;
			color: #212121;
		}
		.contact-info-title {
			margin-top: 16px;
			margin-bottom: 0;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 1.25rem;
			line-height: 24px;
			color: #212121;
		}
		.flag-container {
			margin-top: 28px;
			.flag {
				width: 32px;
			}
			.call-text {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: 450;
				font-size: 0.875rem;
				line-height: 16px;
				color: #212121;
			}
		}
	}
}
