@import './variables.scss';
.filter {
	height: 40px;
	border-radius: 8px;
	font-family: $CircularStdBlack;
	font-style: normal;
	font-weight: 450;
	font-size: 0.875rem;
	line-height: 1rem;
	border: 0px solid transparent;
	margin-top: 8px;
	.filter-button-icon {
		width: 20px;
		position: relative;
		left: -7px;
		z-index: 0;
	}
	span {
		position: relative;
	}
}
.filter:focus {
	border: 1px solid #d0d0d0;
}

.filter-name-text {
	margin-top: 16px;
	margin-bottom: 16px;
	font-family: $CircularStdBlack;
	font-style: normal;
	font-size: 1rem;
	line-height: 149.5%;
	color: #000000;
}

.active-filter {
	background-color: #212121;
	color: #fff;
}
.inactive-filter {
	background-color: #f4f4f4;
	color: #63666a;
}
