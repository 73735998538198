@import './variables.scss';
.help-status-work {
	height: 92vh;
	position: relative;
	overflow-y: scroll;
	.col-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-height: 100%;
		max-width: 92%;
		min-height: 100%;
		.help-image {
			margin-top: 14px;
		}
		.order-status-title {
			margin-bottom: 0;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 32px;
			display: flex;
			align-items: center;
			color: #212121;
		}
		.order-status-description-1 {
			margin-top: 8px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 1.125rem;
			line-height: 23px;
			color: #63666a;
		}
		.order-status-description-2 {
			margin-top: 24px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 1.125rem;
			line-height: 23px;
			color: #63666a;
		}
		.bullet {
			float: left;
			margin-right: 16%;
			margin-bottom: 16px;
		}
	}
}
