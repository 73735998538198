@import './variables.scss';
.profile {
	height: 100vh;
	width: 100%;
	margin: auto;
	background-color: #fff !important;
	position: relative;

	.topbar {
		position: absolute;
		width: 100%;
		height: 48px;
		left: 0px;
		top: 0px;
		background: #ffffff;
	}

	.profile-info {
		overflow-y: auto;
		height: 70vh;
		position: absolute;
		top: 96px;
		padding: 0 16px;
		width: 100%;
		.name {
			height: 32px;
			left: 16px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 24px;
			line-height: 134%;
			color: #212121;
		}
		.member-group {
			// width: 396px;
			width: 100%;
			height: 16px;
			left: 16px;
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 136%;
			color: #212121;
		}
		.info-list {
			.info-title {
				font-family: $CircularStdBlack;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 149.5%;
				color: #212121;
				margin: 16px 0;
			}
		}
	}
}
