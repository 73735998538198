@import './variables.scss';
.onboarding-container {
	position: relative;
	background: rgb(255, 255, 255);
	height: 100vh;

	.img-container {
		object-fit: contain;
		position: absolute;
		top: 120px;
		width: 100%;
		padding: 0 16px;
		svg {
			width: 100%;
		}
	}

	.list-container {
		padding: 0 16px;
		svg {
			width: 100%;
		}
	}

	.description {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: absolute;
		width: 100%;
		height: 14.25vh;
		left: 0px;
		top: 49.24vh;
		padding: 0 16px;
		.title-text {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 1.2rem;
			line-height: 130%;
			display: flex;
			align-items: center;

			/* Text/Black */
			color: #212121;
			margin: 8px 0;
			width: 100%;
			float: left;
		}
		.body-text {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: 450;
			font-size: 1rem;
			line-height: 23px;

			/* Text/Grey 04 */
			color: #63666a;
			width: 100%;
			float: left;
		}
	}
	.pagination-list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;

		position: absolute;
		width: 92px;
		height: 8px;
		left: calc(50% - 92px / 2);
		top: 83.8vh;
	}
	.button-container {
		position: absolute;
		width: 100%;
		height: 5.18vh;
		padding: 0 16px;
		top: 86.39vh;
	}
	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		background: #ca0d1b;
		color: #fff;
		cursor: pointer;
	}

	.button-container-flex {
		display: flex;
		justify-content: space-between;
	}

	.skip-all,
	.next {
		width: 45%;
	}

	.skip-all {
		background: #fff;
		color: #ca0d1b;
		border: 1px solid #ca0d1b;
	}
	.img-container1 {
		top: 6.91vh;
		img {
			width: 90%;
		}
	}
	.img-container2 {
		top: 10vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 40vh;
		}
	}
	.img-container3 {
		top: 9vh;
	}
	.img-container3 {
		.img2 {
			top: 13.28vh;
			position: absolute;
			width: 91.5vw;
		}
		.img3 {
			position: absolute;
			top: 23.32vh;
			width: 91.5vw;
		}
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 47vh;
		}
	}
	.img-container4 {
		position: absolute;
		top: 8.72vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 45vh;
		}
	}
	.img-container5 {
		top: 8.72vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 50vh;
		}
	}
	.img-container6 {
		top: 8.72vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 50vh;
		}
	}
	.img-container7 {
		top: 8.72vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 46vh;
		}
	}
	.img-container8 {
		top: 1vh;
		.ant-image {
			width: 100%;
		}
		.ant-image-img {
			height: 45vh;
		}
	}
	.description3 {
		top: 54vh;
	}
	.description4 {
		top: 54vh;
	}
	.description5 {
		top: 57vh;
	}
	.description6 {
		top: 59vh;
	}
	.description7 {
		top: 56vh;
	}
}
.text-overflow-auto {
	.description {
		overflow-y: auto;
		height: 20vh;
	}
}
