.top-bar {
	height: 7vh;
	min-height: 48px;
	background: #ffffff;
	box-shadow: 0px 2px 5px rgba(162, 162, 162, 0.1);
	.placeholder {
		margin-top: 1vh;
		height: 5vh;
		min-height: 40px;
	}
	.back-button {
		border: 0px solid transparent;
		margin-top: 1.3vh;
	}
	.back-button > span {
		font-family: 'Circular Std';
		font-style: normal;
		font-weight: 450;
		font-size: 0.875rem;
		line-height: 1.125rem;
		color: #ca0d1b;
	}
	.back-button > span > img {
		height: 1.063rem;
	}
	.back-button > span:nth-of-type(2) {
		margin-left: 4px;
	}
}
