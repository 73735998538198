@import '~antd/dist/antd.css';
@import 'styles/variables.scss';

.full-height {
	height: 100vh;
}
.horizontal-center {
	align-items: center;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #f4f4f4 !important;
}
body {
	background-color: $self-serve-primary-background-color;
	overflow-y: hidden !important;
}
.common-page-comtainer {
	background-color: $self-serve-primary-foreround-color;
	width: 100vw;
	height: 100vh;
	overflow: hidden !important;
}
#root {
	overflow-y: hidden !important;
}
.page-header-text {
	font-family: $self-serve-primary-font-family;
	font-style: normal !important;
	font-weight: 900 !important;
	font-size: 1rem !important;
	line-height: 134% !important;
	margin-top: 24px;
}
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
	color: #f4f4f4;
	border-color: #f4f4f4;
}
.common-deactive-button {
	border: 1px solid #ca0d1b;
	box-sizing: border-box;
	width: 100%;
	height: 48px;
	font-family: $CircularStdBlack;
	font-style: normal;
	font-weight: 900;
	font-size: 0.875rem;
	line-height: 16px;
	color: #ca0d1b;
}
.common-active-button {
	height: 48px;
	width: 100%;
	background: #ca0d1b;
	font-family: $CircularStdBlack;
	font-style: normal;
	font-weight: 900;
	font-size: 0.875rem;
	line-height: 16px;
	color: #ffffff;
}
.inactive-bullet {
	background: #ffffff;
	border: 1px solid #d8d8d8;
	box-sizing: border-box;
	width: 8px;
	height: 8px;
	border-radius: 4px;
}
.active-bullet {
	background: #ca0d1b;
	border: 1px solid #d8d8d8;
	box-sizing: border-box;
	width: 8px;
	height: 8px;
	border-radius: 4px;
}
@media screen and (max-width: 600px) {
	body {
		background-color: #fff;
	}
}

.text-overflow-auto {
	.description {
		overflow-y: auto;
		height: 50vh;
	}
}

.landscapeMode {
	.center {
		margin: auto;
		width: 100%;
		padding: 10px;
		p {
			text-align: center;
			font-size: 20px;
		}
	}
}
@media screen and (orientation: landscape) {
	.portraitMode {
		display: none;
	}
	.landscapeMode {
		display: block;
		height: 100%;
	}
	.search-active{
		height: 100% !important;
		width: 100% !important;
		display: block !important;
		overflow-y: hidden !important;
	}
}

@media screen and (orientation: portrait) {
	.portraitMode {
		height: 100%;
		width: 100%;
		display: block;
		overflow-y: hidden;
	}
	.landscapeMode {
		display: none;
	}
	.search-active{
		height: 100% !important;
		width: 100% !important;
		display: block !important;
		overflow-y: hidden !important;
	}
}

.ant-breadcrumb {
	height: 9vh;
	a,
	.ant-breadcrumb-separator {
		line-height: 11vh;
		font-family: 'CircularStdBlack';
		font-style: normal;
		font-weight: 900;
		color: #212121 !important;
		font-size: 1.25rem;
	}
	.disabled,
	.ant-breadcrumb-separator {
		color: #d8d8d8 !important;
	}
}
