@import './variables.scss';
.info-container {
	.desc {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		color: #212121;

		.desc-title {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 136%;
			margin: 4px 0;
		}

		.desc-value {
			font-family: $CircularStdBlack;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 23px;
			margin: 4px 0;
		}
	}
}

.separator {
	// width: 396px;
	width: 100%;
	opacity: 0.25;
	border: 1px solid #5f5f5f;
	margin: 16px 0;
}
